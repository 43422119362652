<template>
<div class="onlyPositionDetailBg">
  <div class="jobDetailCont"  v-if="positonDetailInfor">
    <div class="courseGoodsList couseGoodsListStyle positionDetailCont">
        <div class="positionListItem">
            <div class="positionListItemStyle">
                <p class="positionListTitle">{{positonDetailInfor.positionName}}</p>
                <div class="positionIntroduce">
                    <p class="positionPrice fl" v-if="!positonDetailInfor.salaryStartNum && positonDetailInfor.salaryEndNum">{{positonDetailInfor.salaryEndNum}}K以下</p>              
                    <p class="positionPrice fl" v-if="positonDetailInfor.salaryStartNum && !positonDetailInfor.salaryEndNum">{{positonDetailInfor.salaryStartNum}}K以上</p>
                    <p class="positionPrice fl" v-if="positonDetailInfor.salaryStartNum && positonDetailInfor.salaryEndNum">{{positonDetailInfor.salaryStartNum}}K-{{positonDetailInfor.salaryEndNum}}K</p>
                    <div class="conditions fl">
                        <span>{{educationText[positonDetailInfor.education]}}</span>
                        <span>{{positonDetailInfor.gender == null ? '性别不限' : gender[positonDetailInfor.gender]}}</span>
                        <span v-if="!(positonDetailInfor.startAge && positonDetailInfor.endAge)">年龄不限</span>
                        <span v-else>年龄{{positonDetailInfor.startAge}}-{{positonDetailInfor.endAge}}周岁</span>
                        <span>{{positionType[positonDetailInfor.positionType - 1]}}</span>
                        <span v-if="positonDetailInfor.workPlaceCityName">{{positonDetailInfor.workPlaceCityName}}</span>               
                    </div>
                </div>
                <div class="welfare">
                    <span v-for="(item, idx) in  positonDetailInfor.workBenefits.split(',')" :key="idx">{{item}}</span>
                </div>

            </div>
            <div class="publishTime">发布时间：{{positonDetailInfor.publishTime}}</div>
            
            <div class="companyInforCont">
                <div class="conpanyInforList fl">
                <p class="companyName">{{companyInfor && companyInfor.name}}</p>
                <p class="companyType"><span>{{companyInfor && companyInfor.industryField}}</span></p>      
                <p class="companyType" v-if="false"><span>移动互联网</span><span>D轮及以上</span><span>10000人以上</span></p>     
                </div>
                <div class="companyLogo fl"><img :src="companyInfor && companyInfor.logo"/></div>
            </div>
        </div>
    </div>

    <div class="jobDetailInfor">
        <div class="jobDetailInforLeft fl">
            <div class="jobDetailItem">
                <p class="jobDetailTitle">职位描述</p>
                  <div>
                    <p>{{positonDetailInfor.positionDesc}}</p>
                </div>              
                <div v-if="false">
                    <p>• 工作职责：</p>
                    <p>1、负责手机.智能屏幕类+智能摄像机的人机交互类产品设计</p>
                    <p>2、负责to B ， to Developer型控制台型产品的系统交互设计</p>
                    <p>3、配合上下游，做好设计项目管理</p>
                    <p>4、沉淀出交互规范.设计规范</p>
                </div>
            </div>
            <div class="jobDetailItem" v-if="false">
                <p class="jobDetailTitle">岗位要求</p>
                <div>
                    <p>• 工作职责：</p>
                    <p>1、负责手机.智能屏幕类+智能摄像机的人机交互类产品设计</p>
                    <p>2、负责to B ， to Developer型控制台型产品的系统交互设计</p>
                    <p>3、配合上下游，做好设计项目管理</p>
                    <p>4、沉淀出交互规范.设计规范</p>
                </div>
            </div>   

            <div class="jobDetailItem" v-if="false">
                <p class="jobDetailTitle">福利待遇</p>
                <div>
                    <p>• 工作职责：</p>
                    <p>1、负责手机.智能屏幕类+智能摄像机的人机交互类产品设计</p>
                    <p>2、负责to B ， to Developer型控制台型产品的系统交互设计</p>
                    <p>3、配合上下游，做好设计项目管理</p>
                    <p>4、沉淀出交互规范.设计规范</p>
                </div>
            </div> 
        
        </div>

        <div class="jobDetailInforRight fr">

            <div class="jobDetailRightTop">
                <div class="detailCompanyInforCont">
                    <img :src="companyInfor && companyInfor.logo"/>
                    <div class="detailInforTitle">
                        <p class="smallCompanyName">{{companyInfor && companyInfor.name}}</p>
                        <p class="companyScale" v-if="false"><span>移动互联网</span><span>D轮及以上</span><span>10000人以上</span></p>       
                        <p class="companyScale" v-if="companyInfor && companyInfor.industryField">
                            <span v-for="(item, idx) in companyInfor.industryField.split('|')"   :key="idx">{{item}}</span>
                        </p>          
                    </div>
                             
                </div>

                <div class="hotpositon">
                    <p class="hotPositionTitle fl"><span>{{companyInfor.positionNum}}</span>个热招职位</p>
                    <p class="seeDetailBtn fr"  v-if="false">查看详情</p>               
                </div>
            </div>


            <div class="jobDetailRightBottom">
                <div class="similarPositionCont">
                    <div class="similarTitleBox">
                        <p class="similarTitle fl">相似职位</p>
                        <p class="similarMore fr" v-if="false">
                            更多
                            <svg-icon icon-class="arrow-right"></svg-icon>
                        </p>
                    </div>       
                </div>  
                <div>
                    <div v-if="similarPositionInfor && similarPositionInfor.length" class="simalarPositionStyle">
                        <div class="similarPositonList"  v-for="(item, idx) in  similarPositionInfor" :key="idx" @click="similarPositionHandle(item)">
                            <div v-if="idx < 3">
                                <div class="similarCompanyTitle">
                                    <p class="similarPositonTitle fl">{{item.positionName}}({{item.positionDesc}})</p>
                                    <p class="similarPositonMoney fr">{{item.salaryStartNum}}-{{item.salaryEndNum}}K</p>                   
                                </div>
                                <div class="yaoqiuList">
                                    <span>{{educationText[item.education]}}</span><span>{{positionType[item.positionType - 1]}}</span><span>{{experienceText[item.experience]}}</span>
                                </div>
                                
                                <div class="gongsiInfor">
                                    <img :src="item.companyLogo"/>
                                    <p class="gongsiText">{{item.companyName}}</p>
                                
                                </div>
                            </div>
                        
                        </div> 
                    </div> 
                </div>
   
            </div>
        </div>
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'jobDetail',
  components: {

  },
  data () {
    return {

          companyInfor: null,
          positonDetailInfor: null,
          similarPositionInfor: null,
          educationText: ['不限', '高中', '大专', '本科', '研究生', '博士'],
          gender: ['男', '女'],
          positionType: ['全职', '兼职','合伙人'],
          experienceText: ['不限', '应届毕业生', '3年以下', '3-5年', '5年以上'],
          customNum: 1

    }
  },
  watch:{

  },
  filters: {


  },
  created () {
    this.getFindById()
  },
  computed: {

  },
  mounted() {
      this.companyInfoWithoutMan()
  },

  beforeDestroy() {
  },


  methods: {
      getFindById() {
           let parse = {
               id: Number(this.$route.query.id)
           }
           this.$api.job.findById(parse)
            .then(res=>{
                if (res.data) {

                    this.positonDetailInfor = res.data
                    this.findSimilarPostion()
                    
                } else {
                    this.$message.error(res.message);
                }            
        })
      },


      findSimilarPostion() {
        let currentInfor = JSON.parse(localStorage.getItem('currentPositonInfor'))
        let parse = {
          education: this.positonDetailInfor.education,
          salaryEndNum: !this.positonDetailInfor.salaryEndNum ? null : this.positonDetailInfor.salaryEndNum,
          salaryStartNum: !this.positonDetailInfor.salaryStartNum  ? null : this.positonDetailInfor.salaryStartNum,
          keyWord: currentInfor.keyWord,
        }  
        this.$api.job.findSimilarPostion(parse)
        .then(res=>{
            if (res.data) {
                this.similarPositionInfor = res.data.list
           
            } else {
                this.$message.error(res.message);
            }                       
        })
      },


      companyInfoWithoutMan() {
            let parse = {
               id: Number(this.$route.query.companyId)
           }
           this.$api.job.companyInfoWithoutMan(parse)
            .then(res=>{
                if (res.data) {
                    this.companyInfor = res.data
 
                    
                } else {
                    this.$message.error(res.message);
                }                           
        })
      },

      similarPositionHandle(item) {
        localStorage.setItem('customNum', localStorage.getItem('customNum') ? localStorage.getItem('customNum') + 1 : this.customNum)
        this.$router.push({
            path:"/jobDetail",
            query:{
                id: item.id,
                companyId: item.companyId,
                positionName: localStorage.getItem('customNum')

            }
        })
      },

  },

};
</script>

<style lang="stylus" scoped>
 @import "../css/job.styl"
</style>
