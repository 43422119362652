<template>
    <div class="changeCityCont">
        <div class="changeCityLayer"></div>
        <div class="changeCityPopCont">
            <div class="selectCity">
                <p class="selectCityField">城市选择</p>               
                <div class="selectCityItem">
                    <div>
                        <el-select v-model="value" placeholder="请选择" @change="proviceSelectHandle">
                            <el-option
                            v-for="item in provinceAndCityData"
                            :key="item.province.id"
                            :label="item.province.name"
                            :value="item.province.code">
                            </el-option>
                        </el-select>
                    </div> 

                    <div> 
                        <el-select v-model="cityValue" placeholder="城市"  @change="citySelectHandle">
                            <el-option
                            v-for="item in currentCityData"
                            :key="item.id"
                            :label="item.name"
                            :value="item.code">
                            </el-option>
                        </el-select>
                    </div>  
                </div>         
            </div>

            <div class="cityListShow">
                <p class="letterTitle">按省份首字母选择</p>
                <div class="cityTable">
                    <div class="letterListItem" v-for="(item, idx) in  provinceAndCityData" :key="idx">        
                        <div class="letterText" ref="letterTextRef" :style="{ 'height': letterHeight(idx), 'lineHeight': letterHeight(idx)}">{{item.province.initial}}</div>
                        <div class="cityText">{{item.province.name}}</div>
                        <div class="cityListText" ref='cityListTextRef'>
                            <span  v-for="(listItem, listIdx) in  item.citys" @click="selectListCityHandle(item, listItem)" :key="listIdx">{{listItem.name}}</span>
                        </div>     
                    </div>   
                </div>   
            </div>
            <span class="closeBtn" @click="closeChangePop()">
                <svg-icon icon-class="close"></svg-icon>
            </span>
        </div>

    </div>

</template>

<script>
export default {
    name: "commonPositionList",
    props: ['provinceAndCity'],
    data () {
        return {
            listData: [],
            isLogin: null,
            value: '',
            cityValue: '',          
            provinceAndCityData:[],
            currentCityData: [],
            citysFormartData: {},
            provinceFormartData: {},
            provinceCode: '',
            cityCode: '',
            cityHeight: []

        };
    },

    computed: {

    },

    created(){
        this.provinceAndCityData = this.provinceAndCity
        this.proviceAndCityFormart()
    },

    mounted(){

    },
    destroyed(){
    },

    methods: {

        closeChangePop() {
            let parse = null
            if (this.provinceCode || this.cityCode) {
                parse = {
                    provinceCode: this.provinceCode,
                    priviceName: this.value,
                    cityCode: this.cityCode,
                    cityName:  this.cityValue   
                }
            }

            this.$emit('closeChangeCityPop', parse)
        },

        proviceSelectHandle(currentProviceVal) {
            this.currentCityData = this.citysFormartData['province-' + currentProviceVal]
            console.log('currentProviceVal-----', currentProviceVal, this.currentCityData )
            this.value = this.provinceFormartData && this.provinceFormartData['code-' + currentProviceVal].name            
            this.cityValue = ''
            this.provinceCode = currentProviceVal
            console.log('this.value==', this.value)
        },

        citySelectHandle(currentCityVal) {
              this.cityCode = currentCityVal
              this.currentCityData.forEach((val) => {
                  if (val.code == currentCityVal) {
                      this.cityValue = val.name                     
                      this.closeChangePop()
                      return false
                  }            
            })
        },        
        
        proviceAndCityFormart() {            
            this.provinceAndCityData.forEach((val) => {
                if (!this.provinceFormartData['code-' + val.province.code]) {              
                    this.provinceFormartData['code-' + val.province.code] = val.province
                }
                if (!this.citysFormartData['province-' + val.province.code]) {
                    this.citysFormartData['province-' + val.province.code] = val.citys
                }    
            })
            console.log('this.citysFormartData===', this.citysFormartData)           
        },

        selectListCityHandle(item, listItem) {
             this.provinceCode = item.province.code
             this.currentCityData = this.citysFormartData['province-' + this.provinceCode]
             this.value = item.province.name
             this.cityCode = listItem.code
             this.cityValue = listItem.name
             this.closeChangePop()
        },

        letterHeight(idx) {         
          this.$nextTick(()=>{ 
            if (this.$refs.cityListTextRef[idx]) {
                this.$refs.letterTextRef[idx].style.height =  this.$refs.cityListTextRef[idx].clientHeight + 'px'
                this.$refs.letterTextRef[idx].style.lineHeight =  this.$refs.cityListTextRef[idx].clientHeight + 'px'
            }
          })
        }

    }
}

</script>


<style  lang="stylus" scoped>
  @import "../css/job.styl"
</style>

<style  lang="stylus">
.selectCityItem
  .el-input__inner
    border-radius 0!important

</style>



