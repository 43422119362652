<template>
<div class="onlyCourseGoodsBg">
  <div class="jobIdxCont">
        <div class="courseGoodsList couseGoodsListStyle">
          <commonPositionList :sendPositionListData="positionListData" v-if="positionListData.length"></commonPositionList>
          <paging class="paging pagingCustom" :paging="paging" v-if="paging.totalPages > 1" />
      </div>

  </div>
</div>
</template>

<script>
import commonPositionList from "./commonPositionList";
import paging from "../../../layout/paging";

export default {
  name: 'morePositionList',
  components: {
    paging,
    commonPositionList,
  },
  data () {
    return {         
          paging: {
              params: {
                  pageNum: 1,
                  pageSize: 5
              },
              total: 0,
              totalPages: 0,
              currentSize: 0,
          },

          positionListData: []       
    }
  },
  watch:{

  },
  filters: {
  },
  created () {
  },
  computed: {

  },
  mounted() {
    this.getData()
  },

  beforeDestroy() {
  },

  methods: {
    getData() {
      this.getFindByCondition()
    },
    

    getFindByCondition() {
      let parse = {
          education:  Number(this.$route.query.education),
          experience:  Number(this.$route.query.experience),
          salaryEndNum: this.$route.query.salaryEndNum,
          salaryStartNum:  this.$route.query.salaryStartNum,
          keyWord:  this.$route.query.keyWord,
          pageNum: this.paging.params.pageNum,
          pageSize:  this.paging.params.pageSize,
          workPlaceCity:  this.$route.query.workPlaceCity

      }
      this.$api.job.findByCondition(parse)
      .then(res=>{
          if (res.data) {
            this.positionListData = res.data.list
            this.paging.total = res.data.total; 
            this.paging.totalPages = res.data.pages; 
          } else {
             this.$message.error(res.message);
          }
          
      })

    },
  },

};
</script>

<style lang="stylus" scoped>
 @import "../css/job.styl"
</style>
